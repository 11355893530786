<template>
  <div class="cart-checkout">
    <!-- checkout:info+button START -->
    <h1
      class="headline note-head"
      v-html="$t('terminal.cart.checkout.headline')"
      v-if="!loadingVisibleDialog"
    ></h1>
    <div class="divider" v-if="!loadingVisibleDialog && false"></div>
    <button
      class="btn-inverted btn-checkout note-btn note-btn-new"
      v-on:click="abort()"
      v-on:touchstart="touchStart($event)"
      v-on:touchend="touchEnd($event);"
      v-on:touchcancel="touchEnd($event)"
      v-html="$t('terminal.cart.buttons.abort')"
    ></button> <!-- v-if="!loadingVisibleDialog && false" -->
    <div class="note" v-if="!loadingVisibleDialog"></div>
    <!-- checkout:info+button END -->

    <!-- checkout:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- checkout:loading END -->

    <!-- payment:loading START -->
    <loading-default
      :loadingVisible="loadingVisiblePayment"
      :headline="loadingHeadlinePayment"
      :excerpt="loadingExcerptPayment"
      :showRestoreCart="showRestoreCart"
      :abort="abort"
    />
    <!-- payment:loading END -->

    <!-- invoice:modal START -->
    <dialog-confirm
      :loadingVisible="loadingVisibleDialog"
      :headline="loadingHeadlineDialog"
      :excerpt="loadingExcerptDialog"
      :open="openDialog"
      :close="closeDialog"
      :confirm="confirmDialog"
    />
    <!-- invoice:modal END -->

    <!-- retry:modal START -->
    <dialog-confirm-retry
      :loadingVisible="loadingVisibleDialogRetry"
      :headline="loadingHeadlineDialogRetry"
      :excerpt="loadingExcerptDialogRetry"
      :open="openDialogRetry"
      :close="closeDialogRetry"
      :confirm="confirmDialogRetry"
      :data="orderToken"
    />
    <!-- retry:modal END -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'cart-checkout',
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    DialogConfirm: () => import('../../components/shared/dialog/Confirm.vue'),
    DialogConfirmRetry: () => import('../../components/shared/dialog/ConfirmRetry.vue'),
  },
  data() {
    return {
      /**
       * Settings
       */
      settings: null,
      intervalValue: 10000,

      scannedString: '',

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('terminal.loading.checkout.headline'),
      loadingExcerpt: this.$t('terminal.loading.checkout.description'),

      /**
       * Loading
       */
      loadingVisiblePayment: false,
      loadingHeadlinePayment: this.$t('terminal.cart.checkout.headline'),
      loadingExcerptPayment: '',
      showRestoreCart: false,

      /**
       * Dialog
       */
      loadingVisibleDialog: false,
      loadingHeadlineDialog: this.$t('terminal.cart.checkout.loading.ec.headline'),
      loadingExcerptDialog: this.$t('terminal.cart.checkout.loading.ec.excerpt'),

      /**
       * Dialog (Retry)
       */
      loadingVisibleDialogRetry: false,
      loadingHeadlineDialogRetry: this.$t('terminal.cart.checkout.loading.error.headline'),
      loadingExcerptDialogRetry: this.$t('terminal.cart.checkout.loading.error.excerpt'),

      /**
       * Dialog (Payment Categories)
       */
      loadingVisibleDialogPaymentCategorie: false,
      loadingHeadlineDialogPaymentCategorie: this.$t('terminal.cart.checkout.loading.payment.headline'),
      loadingExcerptDialogPaymentCategorie: this.$t('terminal.cart.checkout.loading.payment.excerpt'),
      selectedOption: null,

      /**
       * Payment Category ID
       */
      paymentCategoryID: null,

      /**
       * Invoice
       */
      invoice: false,

      hasPayment: null,

      orderToken: null,
    };
  },
  async created() {
    // get & set settings
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.intervalValue = this.settings.app.timeouts.payment;
    }

    // set payment categorie ID && create order
    this.paymentCategoryID = this.$route.query.paymentCategoryID;
    if (this.paymentCategoryID) {
      if (this.$route.query.paymentCategoryName && this.$route.query.paymentCategoryName === 'InApp') {
        // create now order
        this.createOrderFinal(this.paymentCategoryID);
      }
      if (this.$route.query.paymentCategoryName && this.$route.query.paymentCategoryName === 'InShop') {
        // open dialog
        this.openDialog();
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  computed: {
    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },
  },
  methods: {
    ...mapActions([
      'createOrder',
      'clearCart',
      'getPayment',
      'setPaymentRetry',
    ]),

    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    createOrderFinal(catID) {
      this.paymentCategoryID = catID;

      if (this.$route.query.paymentCategoryName === 'InApp') {
        this.createOrder({
          paymentCategoryID: this.paymentCategoryID,
          printInvoice: 0,
        });
        // redirect to thank you screen
        setTimeout(() => {
          this.$router.push('/cart/thank-you');
        }, 125);
      }
    },

    async getPaymentInterval(token) {
      try {
        const result = await this.getPayment({
          orderToken: token,
        });
        if (result && result.status === 1) { // PENDING
          setTimeout(() => {
            this.getPaymentInterval(token);
          }, this.intervalValue);
        }
        if (result && result.status === 2) { // SUCCESS
          this.loadingVisiblePayment = false;

          if (this.invoice === true) {
            this.$router.push({ path: '/cart/thank-you', query: { invoice: true, qrcode: result.digitalReceiptQrCode } });
          } else {
            this.$router.push({ path: '/cart/thank-you', query: { invoice: false, qrcode: result.digitalReceiptQrCode } });
          }
          this.$router.push({ path: '/cart/thank-you', query: { qrcode: result.digitalReceiptQrCode } });
          // this.$router.push('/cart/thank-you');
        }
        if (result && result.status === 3) { // ERROR
          this.loadingVisiblePayment = false;
          this.openDialogRetry();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createOrderCard() {
      // this.loadingVisible = true;
      let printInvoice = 0;
      if (this.invoice === true) {
        printInvoice = 1;
      }

      try {
        this.loadingVisiblePayment = false;

        if (this.orderToken === null) {
          const result = await this.createOrder({
            paymentCategoryID: this.paymentCategoryID,
            printInvoice,
          });

          if (result) {
            const { token } = result;
            const { payStatus } = result;

            if (payStatus === 1) {
              if (this.invoice === true) {
                this.$router.push({ path: '/cart/thank-you', query: { invoice: true } });
              } else {
                this.$router.push({ path: '/cart/thank-you', query: { invoice: false } });
              }
            } else {
              this.orderToken = token;

              // get payment
              const resultPayment = await this.getPayment({
                orderToken: token,
              });
              if (resultPayment && resultPayment.status === 1) { // PENDING
                this.loadingVisiblePayment = true;
                // call interval (get payment)
                this.getPaymentInterval(token);
              }
              if (resultPayment && resultPayment.status === 2) { // SUCCESS
                this.loadingVisiblePayment = false;

                if (this.invoice === true) {
                  this.$router.push({ path: '/cart/thank-you', query: { invoice: true } });
                } else {
                  this.$router.push({ path: '/cart/thank-you', query: { invoice: false } });
                }
                this.$router.push('/cart/thank-you');
              }
              if (resultPayment && resultPayment.status === 3) { // ERROR
                this.loadingVisiblePayment = false;
                this.openDialogRetry();
              }
            }
          }
        } else {
          const result = await this.setPaymentRetry({
            orderToken: this.orderToken,
          });
          if (result) {
            // get payment
            const resultPayment = await this.getPayment({
              orderToken: this.orderToken,
            });
            if (resultPayment && resultPayment.status === 1) { // PENDING
              this.loadingVisiblePayment = true;
              // call interval (get payment)
              this.getPaymentInterval(this.orderToken);
            }
            if (resultPayment && resultPayment.status === 2) { // SUCCESS
              this.loadingVisiblePayment = false;

              if (this.invoice === true) {
                this.$router.push({ path: '/cart/thank-you', query: { invoice: true } });
              } else {
                this.$router.push({ path: '/cart/thank-you', query: { invoice: false } });
              }
              this.$router.push('/cart/thank-you');
            }
            if (resultPayment && resultPayment.status === 3) { // ERROR
              this.loadingVisiblePayment = false;
              this.openDialogRetry();
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    abort() {
      // reset store cart
      this.clearCart();
      // redirect to home
      // this.$router.push('/');
      // redirect to home + reload app
      window.location.assign('/');
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    openDialog() {
      this.loadingVisibleDialog = true;
    },

    closeDialog() {
      this.invoice = false;
      this.loadingVisibleDialog = false;
      // create order
      this.createOrderCard();
      setTimeout(() => {
        const note = document.querySelector('.note');
        const noteHead = document.querySelector('.note-head');
        const noteBtn = document.querySelector('.note-btn');
        if (note) {
          note.classList.add('loaded');
          noteHead.classList.add('loaded');
          noteBtn.classList.add('loaded');
        }
      }, 325);
    },

    confirmDialog() {
      this.invoice = true;
      this.loadingVisibleDialog = false;
      // create order
      this.createOrderCard();
      setTimeout(() => {
        const note = document.querySelector('.note');
        const noteHead = document.querySelector('.note-head');
        const noteBtn = document.querySelector('.note-btn');
        if (note) {
          note.classList.add('loaded');
          noteHead.classList.add('loaded');
          noteBtn.classList.add('loaded');
        }
      }, 325);
    },

    // openDialogPaymentCategorie() {
    //   this.loadingVisibleDialogPaymentCategorie = true;
    // },

    // closeDialogPaymentCategorie() {
    //   this.loadingVisibleDialogPaymentCategorie = false;
    // },

    // confirmDialogPaymentCategorie(catID) {
    //   this.loadingVisibleDialogPaymentCategorie = false;
    //   this.paymentCategoryID = catID;
    //   setTimeout(() => {
    //     this.createOrderFinal(this.paymentCategoryID);
    //   }, 350);
    // },

    openDialogRetry() {
      this.loadingVisibleDialogRetry = true;
    },

    closeDialogRetry() {
      this.loadingVisibleDialogRetry = false;
    },

    async confirmDialogRetry(token) {
      this.loadingVisibleDialog = false;
      this.loadingVisibleDialogRetry = false;
      this.orderToken = token;
      // create retry
      this.createOrderCard();
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
    clearInterval();
    clearTimeout();
  },
};
</script>
